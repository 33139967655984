import {
  DegreeDetailRedesignData,
  DegreeDetailRedesignVariant,
  DegreeDetailRedesignTestResult,
  UseDegreeDetailRedesignTraffic,
} from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";

const CUSTOM_RULE_ID = "degreeDetailPageRedesignTest";

/**
 * Hook for the degree detail redesign experiment.
 * This experiment can return one of two variants:
 * - control: Original design
 * - redesign: New design
 */
const useDegreeDetailRedesignTraffic: UseDegreeDetailRedesignTraffic = () => {
  // Call the Monarch API to get the experiment result
  const { isLoading, value } = useMonarchRule<{
    data: {
      showRedesign: boolean;
      stickyCta?: {
        text?: string;
        hideButton?: boolean;
        url?: string;
      };
    };
  }>(CUSTOM_RULE_ID, {});

  // If still loading or no value returned, return loading state
  if (isLoading || !value) {
    return {
      isLoading,
    } as DegreeDetailRedesignTestResult;
  }

  // Determine the variant based on the returned data
  const variant: DegreeDetailRedesignVariant = value.data.showRedesign
    ? "redesign"
    : "control";

  // Extract data for the experiment
  const data: DegreeDetailRedesignData = {
    stickyCta: value.data.stickyCta,
  };

  // Return the experiment result with proper typing
  return {
    isLoading: false,
    variant,
    data,
  };
};

export default useDegreeDetailRedesignTraffic;
