"use client";

import { ReactNode, useEffect, createContext, useContext } from "react";
import {
  MonarchTestResult,
  useMonarchTestResult,
} from "./useMonarchTestResult";

// Define the context type for experiment data
export interface MonarchContextData {
  [experimentKey: string]: MonarchTestResult;
}

// Create context type for experiment data
export const MonarchDataContext = createContext<MonarchContextData>({});

// Hook to use monarch data with optional type parameter
export function useMonarchData<TestResult extends MonarchTestResult>(
  experimentKey?: string,
): TestResult | null {
  const context = useContext(MonarchDataContext);
  if (!experimentKey || !context[experimentKey]) {
    return null;
  }
  return context[experimentKey] as TestResult;
}

// Fallback loader component
const Spinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
    }}
  >
    <div
      style={{
        border: "4px solid rgba(0, 0, 0, 0.1)",
        borderLeftColor: "#3498db",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        animation: "spin 1s linear infinite",
      }}
    ></div>
    <style jsx>{`
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
);

interface VariantComponentMap {
  // Control variant is required
  control: ReactNode;
  // Other variants are mapped by their variant name
  [key: string]: ReactNode;
}

interface MonarchResolverProps<TestResult extends MonarchTestResult> {
  experimentKey: string;
  variants: VariantComponentMap;
  Loader?: ReactNode;
  // Optional fallback variant if the experiment doesn't return a valid variant
  fallbackVariant?: keyof VariantComponentMap;
  // Optional callback to be called with experiment result
  onMonarchTestResolved?: (result: TestResult) => void;
}

/**
 * MonarchResolver renders the appropriate variant based on experiment results.
 * It supports multiple variants and provides experiment data through context.
 *
 * @returns {ReactNode} The component for the active variant
 */
function MonarchResolver<TestResult extends MonarchTestResult>({
  experimentKey,
  variants,
  Loader = <Spinner />,
  fallbackVariant = "control" as keyof VariantComponentMap,
  onMonarchTestResolved,
}: MonarchResolverProps<TestResult>): ReactNode {
  // Get existing context data
  const existingContextData = useMonarchData();

  // Get experiment result
  const result = useMonarchTestResult<TestResult>(experimentKey);

  // Call the callback if provided
  useEffect(() => {
    if (!result.isLoading && onMonarchTestResolved) {
      onMonarchTestResolved(result);
    }
  }, [result, onMonarchTestResolved]);

  // Show loader while loading
  if (result.isLoading) {
    return Loader;
  }

  // Determine which variant to show
  const variantToShow =
    result.variant && variants[result.variant]
      ? result.variant
      : fallbackVariant;

  // Create context value with the entire test result
  const contextValue = {
    ...existingContextData,
    [experimentKey]: result,
  } as MonarchContextData;

  // Return the component for the active variant wrapped in context
  return (
    <MonarchDataContext.Provider value={contextValue}>
      {variants[variantToShow] || variants.control}
    </MonarchDataContext.Provider>
  );
}

export default MonarchResolver;
