import useDegreeDetailRedesignTraffic from "./rules/degreeDetailRedesignTraffic/useDegreeDetailRedesignTraffic";
import useHomepageExploreTopCoursesTraffic from "./rules/homepageExploreTopCourses/useHomepageExploreTopCourses";

// Define a common interface for experiment results
export interface MonarchTestResult<
  VariantType = string,
  DataType = Record<string, any>,
> {
  isLoading: boolean;
  variant?: VariantType;
  data?: DataType;
}

// Type for experiment hooks
export type MonarchTestHook<
  VariantType = string,
  DataType = Record<string, any>,
> = () => MonarchTestResult<VariantType, DataType>;

// Map of experiment rules
export const ruleMap: Record<string, MonarchTestHook> = {
  useDegreeDetailRedesignTraffic,
  useHomepageExploreTopCoursesTraffic,
};

export function useMonarchTestResult<TestResult extends MonarchTestResult>(
  experimentKey: string,
): TestResult {
  const hook = ruleMap[experimentKey];

  if (!hook) {
    throw new Error(`MonarchTest "${experimentKey}" not found`);
  }

  const result = hook() as TestResult;

  return {
    ...result,
    data: result.data || {},
  } as TestResult;
}
