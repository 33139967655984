"use client";
import { useState } from "react";
import CTALink from "@/components/CTA/ctaLink";
import ProductCardList from "@/components/ProductCardList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useIsMobile from "@/hooks/useIsMobile";
import type { ProductSummary } from "@/lib/productSummary/types";
import type { ProductCardPillsByUUIDClientProps } from "./types";

export default function ProductCardPillsByUUIDClient({
  categories,
  title = "",
  cardLimit,
  location = "",
  html_id = "",
}: ProductCardPillsByUUIDClientProps) {
  const [activeLabel, setActiveLabel] = useState(categories[0].label);
  const isMobile = useIsMobile();

  const getProducts = (products: ProductSummary[]) => {
    if (cardLimit && products.length > cardLimit) {
      return products.slice(0, cardLimit);
    }
    return products;
  };

  return (
    <div className="fullwidth bg-putty-200">
      <div className="container py-8">
        {title && <h2 className="elm-d1">{title}</h2>}
        <Tabs
          value={activeLabel}
          onValueChange={(value) => setActiveLabel(value)}
        >
          <TabsList className="overflow-x-scroll justify-start h-auto pt-4 gap-4 lg:overflow-x-auto">
            {categories?.map(({ label }) => (
              <TabsTrigger
                key={label}
                value={label}
                className="grow-0 rounded-xl border border-white no-underline py-1 px-2 text-primary text-xs font-normal bg-white hover:bg-primary hover:text-white data-[state=active]:bg-white data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:hover:text-white data-[state=active]:hover:bg-primary"
              >
                {label}
              </TabsTrigger>
            ))}
          </TabsList>
          {categories?.map(({ products, label, cta }) => (
            <TabsContent
              key={`${label}-content`}
              value={label}
              forceMount={true}
              className={`${activeLabel === label ? "block" : "hidden"}`}
            >
              <ProductCardList
                products={getProducts(products)}
                location={location}
                shouldScroll={isMobile}
              />
              {cta && (
                <div className="flex justify-center">
                  <CTALink
                    label={cta.label}
                    url={cta.url}
                    size="lg"
                    type="primaryOutline"
                    className="bg-white mt-6"
                    location={location}
                    html_id={html_id}
                  />
                </div>
              )}
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </div>
  );
}
